import { motion } from "framer-motion";
import React from "react";

interface SaleWidgetBackdropProps {
    children: React.ReactNode;
    onClick: () => void;
}
export const SaleWidgetBackdrop = ({ children, onClick }: SaleWidgetBackdropProps) => {
    return (
        <motion.div
            onClick={onClick}
            className="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    );
}