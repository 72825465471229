import {SaleWidgetBackdrop} from "./SaleWidgetBackdrop";
import React from "react";
import { motion } from "framer-motion";

interface SaleWidgetModalProps {
    children: React.ReactNode;
    handleClose: () => void;
}

export const SaleWidgetModal = ({ children, handleClose }: SaleWidgetModalProps) => {
    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0,
        },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            }
        },
        exit: {
            y: "100vh",
            opacity: 0,
        }
    }

    return (
        <SaleWidgetBackdrop onClick={handleClose}>
            <motion.div
                onClick={ (e) => e.stopPropagation() }
                className={"position-relative modal"}
                variants={dropIn}
                initial={"hidden"}
                animate={"visible"}
                exit={"exit"}
            >
                <div
                    onClick={handleClose}
                    className={"text-light position-absolute top-0 end-0 px-3 py-1"}
                    role="button"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512" fill="currentColor"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                </div>
                {children}

            </motion.div>
        </SaleWidgetBackdrop>
    )
}