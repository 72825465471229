import React, {useEffect} from "react"
import {Window} from "../interfaces/Window.model";
import {CalculatorConfig, CalculatorConfigGroup} from "./CalculatorConfigGroup";
import {createRoot} from "react-dom/client";
import {motion, useTime, useTransform} from "framer-motion";
import {SaleWidgetModal} from "./SaleWidgetModal";
import {getCookie, hasCookie, setCookie} from "../lib/cookies";

interface SaleWidgetProps {
    content: string;
    contentButtonText: string;
    contentButtonLink: string;
    widget: string;
    defaultOpen?: boolean;
}
export const SaleWidget = ({content, contentButtonText, contentButtonLink, widget, defaultOpen}: SaleWidgetProps) => {

    const [modalOpen, setModalOpen] = React.useState(false);
    const [widgetVisible, setWidgetVisible] = React.useState(false);
    const [widgetShouldAnimate, setWidgetShouldAnimate] = React.useState(true);

    const close = () => {
        setWidgetShouldAnimate(false);

        setCookie('saleWidget', 'closed', 1)
        setModalOpen(false);
        setWidgetVisible(true);
    }

    const open = () => {
        setCookie('saleWidget', 'open', 1)
        setModalOpen(true);
        setWidgetVisible(false);
    }

    const autoOpen = () => {

        // Widget should not animate on second request
        setCookie('widget-animated', 'false', 30);

        if (getCookie('saleWidget') === 'closed') {
            setWidgetVisible(true);
            return;
        }

        if (!defaultOpen) {
            setWidgetVisible(true);
        } else {
            setModalOpen(true);
        }

    }

    useEffect(() => {
        if(hasCookie('widget-animated')) {
            setWidgetShouldAnimate(false);
        }

        document.addEventListener('cookie-consent-closed', () =>{
            setTimeout(autoOpen, 3000);
        });

        if (hasCookie('cookie-banner')) {
            setTimeout(autoOpen, 3000);
        }

    }, [])

    useEffect(() => {
        function handleEscapeKey(event: KeyboardEvent) {
            if (event.code === 'Escape') {
                close();
            }
        }

        document.addEventListener('keydown', handleEscapeKey)
        return () => document.removeEventListener('keydown', handleEscapeKey)
    }, [])

    const randomDuration = () => 0.5 * 0.07 + 0.43;

    const variants = {
        start: {
            rotate: [0,8.5, 0],
            transition: {
                repeat: 5,
                duration: randomDuration()
            }
        },
        end: {
            rotate: 0,
        }
    };
    const getRandomTransformOrigin = () => {
        const value = 0.8; //(70 * Math.random()) / 100;
        const value2 = 3; //(70 * Math.random()) / 100;
        return {
            originX: value,
            originY: value2
        };
    };

    const callToAction = () => {
        setCookie('saleWidget', 'closed', 1)
        window.location.href = contentButtonLink;
    }

    return (
        <div>
            {widgetVisible && widgetShouldAnimate && (
                <motion.button
                    whileHover={{ rotate: 0, scale: 1 }}
                    whileTap={{ scale: 0.9 }}
                    className="widget"
                    onClick={() => (modalOpen ? close() : open())}
                    style={{ ...getRandomTransformOrigin() }}
                    variants={variants}
                    animate={"start"}
                >
                    <div dangerouslySetInnerHTML={{ __html: widget }}></div>
                </motion.button>
            )}

            {widgetVisible && widgetShouldAnimate === false && (
                <button
                    // whileHover={{ rotate: 0, scale: 1 }}
                    // whileTap={{ scale: 0.9 }}
                    className="widget"
                    onClick={() => (modalOpen ? close() : open())}
                    // style={{ ...getRandomTransformOrigin() }}
                    // variants={variants}
                    // animate={"start"}
                >
                    <div dangerouslySetInnerHTML={{ __html: widget }}></div>
                </button>
            )}

            {modalOpen && (
                <SaleWidgetModal handleClose={close}>
                    <div className={"modal-content w-100 p-4 text-center"} dangerouslySetInnerHTML={{ __html: content }}>

                    </div>
                    <div className="modal-footer text-center bg-white p-4">
                        <button className="btn btn-lg btn-success text-light mt-3" onClick={callToAction}>{ contentButtonText }</button>
                    </div>
                </SaleWidgetModal>
            )}
        </div>
    );
}

let saleWidgetComponents = document.querySelectorAll('.sale-widget-component') as NodeListOf<HTMLElement>;
saleWidgetComponents.forEach((element) => {
    const root = createRoot(element);

    const content = element.querySelector('.sale-widget-component-content')?.innerHTML.toString() ?? '' as string;
    const contentButton = element.querySelector('.sale-widget-component-content-button') as HTMLAnchorElement;
    const widget = element.querySelector('.sale-widget-component-widget')?.innerHTML.toString() ?? '' as string;
    const defaultOpen = element.dataset.defaultOpen === 'true';

    root.render(
        <SaleWidget
            content={content}
            contentButtonText={contentButton.dataset.text ?? ''}
            contentButtonLink={contentButton.dataset.link ?? ''}
            widget={widget}
            defaultOpen={defaultOpen}
        />
    );
});